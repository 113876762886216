/* You can add global styles to this file, and also import other style files */
@use '@angular/material' as mat;
@import "themes/huna";
@import "scss/utilities";
@import "scss/alert";
@import "scss/form";
@import "scss/button";
@import "scss/table";

body {
  margin: 0px;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

a {
  text-decoration: none;
  color: inherit;
  word-break: break-word;
}

label.label {
  font-size: 11px;
  color: rgba(0, 0, 0, 0.54);
  margin-bottom: 10px;
  display: inline-block;
}

label.h2 {
  font-size: 15px;
  color: #2196f3;
  font-weight: 600;
  line-height: 15px;
  display: block;
  margin-bottom: 0.83rem;
}

.container {
  padding: 20px 25px;
  @media(max-width: 600px) {
    padding: 0px 15px;
  }
}

.container-no-media {
  padding: 20px 25px;
}

.mat-drawer {
  min-width: 35%;
  max-width: 50%;
  @media(max-width: 600px) {
    max-width: 85%;
  }
}

.text-center {
  text-align: center;
}

.list-item-active {
  color: white;
  background-color: mat.get-color-from-palette(mat.$blue-palette);
}

div.mat-form-field {
  width: 100%;
  margin: 11px 0;
}


.mat-form-field--no-underline .mat-input-underline {
  background-color: transparent;
}

.fab {
  position: fixed !important;
  z-index: 10000;
  right: 20px;
  bottom: 20px;
}

ngx-dropzone {
  box-sizing: border-box;
  display: flex;
  height: 100px !important;
  cursor: pointer;
  background: #fff;
  color: #717386;
  border: 1px dashed #717386 !important;
  border-radius: 5px;
  font-size: 12px !important;
  text-align: center;
  padding: 20px;
}

.box {
  width: 100%;
  height: 100%;
  min-height: 100px;
  display: flex;
  justify-content: center;
  font-size: 12px;
  align-items: center;
  flex-direction: column;
  text-transform: uppercase;
  color: white;
  line-height: 17px;
  text-align: center;
  font-weight: 300;

  &:hover {
    cursor: pointer;
  }

  &.primary {
    background: mat.get-color-from-palette($candy-app-primary);
  }

  &.accent {
    background: mat.get-color-from-palette($candy-app-accent);
  }

  &.warn {
    background: mat.get-color-from-palette($candy-app-warn);
  }
}

.roboto {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 12px;
  font-weight: 500;
}

.box-small {
  //width: 100%;
  //height: 100%;
  //min-height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-transform: uppercase;
  color: white;
  font-size: 10px;
  line-height: 12px;
  text-align: center;
  font-weight: 300;
  border-radius: 4px;

  &:hover {
    cursor: pointer;
  }

  &.primary {
    background: mat.get-color-from-palette($candy-app-primary);
  }

  &.accent {
    background: mat.get-color-from-palette($candy-app-accent);
  }

  &.warn {
    background: mat.get-color-from-palette($candy-app-warn);
  }
}

mat-toolbar:not(.w-timepicker-time-container) {
  background: white !important;
  border-bottom: 1px solid rgb(224, 224, 224) !important
}

.snackbar-error {
  background: scale-color(mat.get-color-from-palette($candy-app-warn), $saturation: -40%);
  color: white;

  .mat-simple-snackbar-action {
    color: white;
  }
}

.snackbar-warning {
  background: scale-color(mat.get-color-from-palette($candy-app-accent), $saturation: -40%);
  color: #32373d;

  .mat-simple-snackbar-action {
    color: #32373d;
  }
}

.snackbar-background {
  background: rgb(227, 227, 227);
  color: #32373d;

  .mat-simple-snackbar-action {
    color: #32373d;
  }
}

.snackbar-background-warning {
  background: #323232;
}

.snackbar-background-error {
  background: #f5f5f5;
}

.snackbar-background-success {
  background: #f5f5f5;
}

//il container del dialog
/* .mat-dialog-container {
  background: rgba(255, 255, 255, 0.88);
} */

//img icon
.img-icon {
  border-radius: 5px;
  object-fit: cover;
  height: 16px;
  width: 24px;
}

//img icon
.img-icon-M {
  object-fit: contain;
  height: 20px;
  width: 20px;
}

.img-icon-L {
  object-fit: contain;
  height: 24px;
  width: 24px;
}


.img-icon-color-white {
  svg {
    fill: white;
  }
}

.img-icon-color-black {
  svg {
    fill: black;
  }
}

.img-icon-color-disable {
  svg {
    fill: rgba(0, 0, 0, 0.3);
  }
}


.img-icon-color-accent {
  svg {
    fill: $colorAccent;
  }
}

.img-icon-color-stroke-accent {
  svg {
    stroke: $colorAccent;
  }
}

.img-icon-color-primary {
  svg {
    fill: $colorPrimary;
  }
}

.disableIconImg {
  filter: invert(0) !important;
  opacity: 0.3;
}

.invert-color {
  filter: invert(1);
}

.img-icon-xl {
  border-radius: 5px;
  object-fit: cover;
  height: 64px;
  width: 64px;
}

.img-icon-xxl {
  border-radius: 5px;
  object-fit: cover;
  height: 128px;
  width: 128px;
}

.img-object-fit-scale-down {
  border-radius: 5px;
  object-fit: scale-down;
  max-height: 100%;
  width: 100%;
}


//prima di scrivere il form
//.mat-form-field-empty.mat-form-field-label {
//  background: green;
//  color:purple;
//}
//
//.mat-form-field-underline {
//  background-color: green;
//  color:blue;
//}

// .mat-form-field {
//   //text non edit
//   .mat-input-element {
//     color: #39414c;
//   }

//   .mat-form-field-label {
//     color: grey;
//   }
//   .mat-form-field-underline {
//     background-color: green;
//   }

//   //line color
//   //.mat-form-field-ripple {
//   //  background-color: yellow;
//   //}

//   //.mat-form-field-required-marker {
//   //  color: orange;
//   //}
//   //.mat-input-wrapper {
//   //  background: red;
//   //}

// }

.mat-drawer-container {
  background-color: #f5f5f5 !important;
  color: rgba(0, 0, 0, 0.87) !important;
}

.mat-pseudo-checkbox-checked, .mat-pseudo-checkbox-indeterminate, .mat-accent .mat-pseudo-checkbox-checked, .mat-accent .mat-pseudo-checkbox-indeterminate {
  background: mat.get-color-from-palette($candy-app-primary);
}

.gm-style .gm-style-iw-c {
  padding-bottom: auto !important;
  padding: 20px 40px !important;
}

// app-dashboard-sidenav img {
//   //transform: rotate(90deg) translateY(-100%);
//   //transform-origin: top left;
// }

.button-container {
  display: flex;
  flex-direction: column;
  align-items: center;

  .buttons {
    cursor: pointer;
    margin-bottom: -15px;
    margin-left: 1px;
    padding: 0px 1px 15px 0px;
    background-color: mat.get-color-from-palette($candy-app-accent);
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: space-between;
  }


  .open-close-button {
    border-radius: 50%;
    background-color: mat.get-color-from-palette($candy-app-accent);
    padding: 8.5px;
  }
}

.button-container-column {
  display: flex;
  flex-direction: column;
  align-items: center;

  .buttons {
    background-color: mat.get-color-from-palette($candy-app-accent);
    border-bottom-left-radius: 10px;
    border-top-left-radius: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }

  .open-close-button {
    border-radius: 50%;
    background-color: mat.get-color-from-palette($candy-app-accent);
    padding: 10px;
  }
}

.comment {
  border-radius: 10px;
  background-color: mat.get-color-from-palette($candy-app-primary, 50);
}

.comment-author {
  background-color: mat.get-color-from-palette($candy-app-accent, 50);
  border-radius: 10px;
}

.comment-label {
  color: rgba(0, 0, 0, .86);
  font-weight: lighter;
  font-size: 10px;
}

.comment-align-right {
  justify-content: flex-start;
  align-items: flex-end;
}

.comment-align-left {
  justify-content: flex-start;
  align-items: flex-start;
}


.custom-dialog-container {
  max-width: unset !important;
  width: 100%;
  height: 100%;

  .mat-dialog-container {
    max-width: 100vw;
    max-height: 100vh;
    height: 100%;
    width: 100%;

    .mat-dialog-content {
      max-height: unset !important;
    }
  }
}

.custom-dialog-container-unset-max-height {
  .mat-dialog-content {
    max-height: unset !important;
  }

}

// this overflow scroll is the cause of several ui issues
// commented till it can be figured out if it was fixing some other problem,
// but even in that case a different approach should be preferred
/* .cdk-overlay-pane {
  overflow: scroll !important;
} */

@mixin base-scrollbar {
  &::-webkit-scrollbar {
    display: unset;
    border-radius: 3px;
    width: 4px;
    height: 4px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, .26); // like angular material
    border-radius: 3px;
  }
}

.mat-select-panel, .mat-dialog-content, .mat-drawer-inner-container, .mat-autocomplete-panel {
  @include base-scrollbar();
}

::-webkit-scrollbar {
  display: none;
}

.scrollbar-visible {
  @include base-scrollbar();
}

#settings .mat-list-item-content {
  padding-left: 0;
  padding-right: 0;
}

//stare attenti dovrebbe essere il 100% della mappa da visualizzare
#mat-tab-group.height-forced {
  height: calc(100vh - 190px);

  .mat-tab-body-wrapper {

    height: 100% !important;
    max-height: 100% !important;
  }
}


.container-linee {
  margin-bottom: 30px;
  padding: 10px;
  width: 380px;
  max-width: calc(100% - 20px);
  display: flex;
  flex-direction: row;
  flex-flow: wrap;
  justify-content: center;
  align-items: center;
  background: #f5f5f5;
  border-bottom: 1px solid #949494;


  .items-linee {
    display: flex;
    flex-direction: row;
    flex-flow: wrap;
    justify-content: center;
    align-items: center;
  }

}

// agm-info
.gm-style .gm-style-iw {
  min-width: 100px !important;
  padding: 10px !important;
}

.mat-tab-label-content {
  width: 100%;
}


.huna-material-class-remote-control .mat-tab-header {
  z-index: 999;
  width: 100%;
  position: fixed !important;
  background: #f5f5f5;
  overflow: hidden;
}

.huna-material-class-open-new-tab .mat-tab-header {
  z-index: 999;
  width: 100%;
  position: fixed !important;
  background: #f5f5f5;
  overflow: hidden;
}


.huna-material-class-street-form .mat-tab-header {
  z-index: 999;
  width: 100%;
  max-width: calc(100vw - (100vw - 600px));
  position: fixed !important;
  overflow: hidden;
  background: white;
}

@media(max-width: 600px) {
  .huna-material-class-street-form .mat-tab-header {
    max-width: calc(100vw - 40px);
  }
}


.huna-material-class-open-new-tab .mat-tab-body-wrapper {
  position: relative !important;
  margin-top: 49px;
  overflow: hidden;
}

.mat-tab-group-sub {
}

.huna-material-class-remote-control .mat-tab-body-wrapper {

  position: relative !important;
  margin-top: 50px;
  overflow: hidden;
}

.huna-material-class-street-form .mat-tab-body-wrapper {
  position: relative !important;
  margin-top: 50px;
  overflow: scroll !important;
}

.overflow-container {
  overflow: scroll;
  max-height: calc(100vh - 156px);
}

.huna-material-class {
  .mat-expansion-panel-body {
    padding: 0;
  }
}

.huna-material-class .mat-grid-tile .mat-figure {
  margin-left: 0 !important;
  justify-content: center !important;
}

.huna-material-class .mat-badge-content {
  color: white;
}


.custom-full-screen-dialog-container .mat-dialog-container {
  padding: 0 !important;
}


.container-schede-manutenzione {
  overflow-x: hidden;

  .cdk-virtual-scroll-content-wrapper {
    overflow-x: hidden;
    max-width: 100%;
  }
}

.mat-badge-content {
  //background: red;
  color: white !important;
}

.spacer-absolute-position-button {
  height: 50px;
}

.ngx-theme {
  padding: 5px 0 5px 0;

  .ngx-timepicker-control__input {
    background: rgb(245, 245, 245);
  }
}

.example-month-picker {
  z-index: 10001 !important;

}

.timepicker {
  overflow: hidden;
}

.timepicker-overlay {
  z-index: 1100 !important;
}

.timepicker-backdrop-overlay {
  z-index: 1100 !important;
}

.circle-color-white {
  &.mat-progress-spinner circle, &.mat-spinner circle {
    stroke: white !important;
  }
}

.mat-card {
  overflow: hidden; // this way it's possible to add a background color to the title without losing the border radius
}

.remove-attachment-button {
  cursor: pointer;
  border: none;
  background: none;
  text-transform: none;
  font-size: 12px;
  padding: 0;
  margin-top: 5px;
  transition: opacity 150ms ease-in-out;

  &:hover {
    opacity: 0.8;
  }
}

.radio-list {
  .mat-radio-label {
    flex-direction: row-reverse;
    justify-content: space-between;
  }

  .mat-radio-label-content {
    padding-left: 0;
    display: inline-flex;
    flex-flow: column;
  }
}

.truncate-middle {
  display: flex;
  justify-content: center;
  align-items: center;

  .w50-flex-1 {
    flex: 1;
    overflow: hidden;
    text-align: end;
  }

  .ellipsis {
    white-space: pre;
    text-overflow: ellipsis;
  }

  .rtl {
    white-space: nowrap;
    direction: RTL;
    unicode-bidi: embed;
  }
}


.huna-mat-list-option {
  mat-list-option {
    width: 60px !important;
    height: 48px !important;
    background: none !important;
  }

  .type-radio {
    .like-radio {
      .external {
        border-radius: 50%;
        border: 1px solid black;
        width: 22px;
        height: 22px;
      }
    }

    mat-list-option[aria-selected="true"] {
      .like-radio {
        .internal {
          background: white;
          width: 18px;
          height: 18px;
          margin: 2px;
          border-radius: 50%;
        }
      }
    }
  }
}

.huna-expansion-panel-color-header {
  .mat-expansion-panel {
    box-shadow: none !important;
    margin: unset !important;
    padding: unset !important;
    border-bottom: 1px solid grey;
  }

  .mat-expansion-panel-header {
    background: $colorPrimary;
  }

  .mat-expansion-panel-header:hover {
    //padding: unset !important;
    background: $colorPrimary !important;
  }

  .mat-expansion-panel-header-description {
    color: white;
  }

  .mat-expansion-panel-header-title {
    color: white;
    font-weight: bold;
  }

  .mat-expansion-indicator:after {
    color: white;
  }
}

button.mat-list-item {
  text-transform: none;
  font-weight: normal !important;
}

.mat-menu-panel {
  max-width: none !important;
}