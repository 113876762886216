.huna-table {
  .mat-sort-header-container {
    justify-content: center !important;
    width: 100%;
  }

  table {
    width: 100%;
  }

  td, th {
    min-width: 150px;
    padding-right: 5px;
    text-align: center;
  }

  td.mat-cell {
    padding: 5px;
  }




  th.mat-header-cell {
    text-align: center;
  }

}
.selectable-rows {
  tr:hover {
    background: $colorAccentTransparent;
    cursor: pointer;
  }
}