@use '@angular/material' as mat;

.my-10 {
  margin-bottom: 10px !important;
  margin-top: 10px !important;
}

.w100 {
  width: 100%;
}

.h100 {
  height: 100%;
}

.spacer {
  margin-bottom: 10px;
  margin-top: 10px;
}

.spacer-plus {
  margin-bottom: 22px;
  margin-top: 22px;
}

.spacer-v {
  margin-left: 10px;
  margin-right: 10px;

  &:last-of-type {
    margin-right: 0px;
  }

  &:first-of-type {
    margin-left: 0px;
  }
}

.scrollable {
  overflow: scroll;
}

:not(.form-field-without-margin) {
  & > .mat-form-field-wrapper {
    margin-bottom: 10px;
  }
}

.btn-filter {
  width: 200px;
}

.font-weight-600 {
  font-weight: 600 !important;
}

$colorAccent: mat.get-color-from-palette($candy-app-accent, default);
$colorAccentLight: mat.get-color-from-palette($candy-app-accent, 200);
$colorPrimary: mat.get-color-from-palette($candy-app-primary, default);
$colorPrimaryLight: mat.get-color-from-palette($candy-app-primary, 100);
$colorPrimaryTransparent: mat.get-color-from-palette($candy-app-primary, 400, 0.1);
$colorAccentTransparent: mat.get-color-from-palette($candy-app-accent, 400, 0.1);
$colorWarn: mat.get-color-from-palette($candy-app-warn, default);
$light-yellow: mat.define-palette(mat.$yellow-palette, 700);
$light-green: mat.define-palette(mat.$green-palette, 600);
$light-purple: mat.define-palette(mat.$purple-palette, 600);
$disableColor: #b0b0b0;
.color-accent {
  color: $colorAccent !important;
}

.color-white {
  color: white !important;
}

.color-warn {
  color: $colorWarn !important;
}

.background-accent {
  background: $colorAccent !important;
  color: mat.get-contrast-color-from-palette($candy-app-accent, 800) !important;
}

.background-accent-transparent {
  background: $colorAccentTransparent !important;
}


tr.element-row-accent:not(.example-expanded-row):hover {
  background: $colorAccentTransparent !important;
}

tr.element-row-primary:not(.example-expanded-row):active {
  background: #efefef;
}

tr.element-row-primary:not(.example-expanded-row):hover {
  background: $colorPrimaryTransparent !important;
}

tr.element-row-primary:not(.example-expanded-row):active {
  background: #efefef;
}


.color-primary {
  color: $colorPrimary !important;
}

.color-green {
  color: mat.get-color-from-palette($light-green, default) !important;
}

.background-primary {
  background: $colorPrimary !important;
  color: mat.get-contrast-color-from-palette($candy-app-primary, 600) !important;
}

.text-with-border-border-black {
  text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000;
}

.background-primary-transparent {
  background: $colorPrimaryTransparent !important;
}
.background-primary-transparent-hover:hover {
  background: $colorPrimaryTransparent !important;
}

.background-purple {
  background: #ac72ad !important;
}

.background-green {
  background: mat.get-color-from-palette($light-green, 600) !important;
  color: mat.get-contrast-color-from-palette($light-green, 600) !important;
}

.background-gray {
  background: mat.get-color-from-palette(mat.$gray-palette, 100) !important;

}

.color-gray {
  color: rgba(0, 0, 0, 0.57);
}

.background-other-color {
  background: mat.get-color-from-palette(mat.$grey-palette, 700) !important;
  color: mat.get-contrast-color-from-palette(mat.$grey-palette, 700) !important;
}


.background-warn {
  background: $colorWarn !important;
  color: mat.get-contrast-color-from-palette($candy-app-warn, 600) !important;
}


.background-color-warn-gradient-vertical {
  background-image: linear-gradient(mat.get-color-from-palette($candy-app-warn, default, 1), mat.get-color-from-palette($candy-app-warn, default, 0.6));
}

.background-color-primary-gradient-vertical {
  background-image: linear-gradient(mat.get-color-from-palette($candy-app-primary, default, 1), mat.get-color-from-palette($candy-app-primary, default, 0.6));
}

.background-color-accent-gradient-vertical {
  background-image: linear-gradient(mat.get-color-from-palette($candy-app-accent, default, 1), mat.get-color-from-palette($candy-app-accent, default, 0.6));
}

.background-color-yellow-gradient-vertical {
  background-image: linear-gradient(mat.get-color-from-palette($light-yellow, default, 1), mat.get-color-from-palette($light-yellow, default, 0.6));
}

.background-color-green-gradient-vertical {
  background-image: linear-gradient(mat.get-color-from-palette($light-green, default, 1), mat.get-color-from-palette($light-green, default, 0.6));
}

.background-color-purple-gradient {
  background-image: linear-gradient(to right, mat.get-color-from-palette($light-purple, default, 0.6), mat.get-color-from-palette($light-purple, default, 1));
}

.background-color-purple-gradient-vertical {
  background-image: linear-gradient(mat.get-color-from-palette($light-purple, default, 1), mat.get-color-from-palette($light-purple, default, 0.6));
}

.low-priority {
  .background {
    background-color: mat.get-color-from-palette($light-green);
  }
}

.medium-priority {
  .background {
    background-color: mat.get-color-from-palette($light-yellow);
  }
}

.high-priority {
  .background {
    background-color: mat.get-color-from-palette($candy-app-warn);
  }
}

.selected {
  .low-priority {
    outline: 2px solid mat.get-color-from-palette($light-green);
    box-shadow: none;

    .default-color-text {
      color: mat.get-color-from-palette($light-green);
    }
  }

  .medium-priority {
    outline: 2px solid mat.get-color-from-palette($light-yellow);
    box-shadow: none;

    .default-color-text {
      color: mat.get-color-from-palette($light-yellow);
    }
  }

  .high-priority {
    outline: 2px solid mat.get-color-from-palette($candy-app-warn);
    box-shadow: none;

    .default-color-text {
      color: mat.get-color-from-palette($candy-app-warn);
    }
  }
}

.border-color-primary {
  border-color: $colorPrimary;
}

.border-color-accent {
  border-color: $colorAccentLight;
}

.border-color-warn {
  border-color: $colorWarn !important;
}


.card-resulted {
  border: 1px solid $colorPrimary;
  opacity: 1;
}

.card-not-resulted {
  box-shadow: unset;
  opacity: 0.5;
}

.dialog-lock-body {
  overflow: hidden !important;
  height: 100VH !important;
}


.flex-center-width100 {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
}

.flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.flex-flow-column {
  flex-wrap: wrap;
  flex-flow: column;
}

.flex-flow-row {
  flex-flow: row;
  flex-wrap: wrap;
}

.flex-start-center {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.flex-center-start {
  display: flex;
  align-items: flex-start;
  justify-content: center;
}


.flex-center-Between {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.flex-start-around {
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.flex-start-start {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.flex-start-stretch {
  display: flex;
  justify-content: flex-start;
  align-items: stretch;
}

.flex-around-stretch {
  display: flex;
  justify-content: space-around;
  align-items: stretch;
}

.flex-between-stretch {
  display: flex;
  justify-content: space-between;
  align-items: stretch;
}

.flex-around-center {
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.flex-end-center {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}


.mat-grid-tile .mat-figure {
  margin-left: 20px !important;
  font-size: small !important;
  color: #656565 !important;
  justify-content: flex-start !important;
  align-items: center !important;
}


.menu-select-card {
  background: $colorPrimary;
  color: white !important;
}

.menu-accent-card {
  background: $colorAccent;
  color: white !important;
}

.menu-select-card-warn {
  background: $colorWarn;
  color: white !important;
}

.box-shadow-3d {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}

// We need to use ::ng-deep to overcome view encapsulation
.custom-slider-primary .ngx-slider .ngx-slider-bar {
  background: #ffe4d1;
  height: 2px;
}

.custom-slider-primary .ngx-slider .ngx-slider-selection {
  background: $colorPrimary;
}

.custom-slider-primary .ngx-slider .ngx-slider-pointer {
  width: 10px;
  height: 10px;
  top: auto; /* to remove the default positioning */
  bottom: -2px;
  background-color: #333;
  border-radius: 50%;
}

.custom-slider-primary .ngx-slider .ngx-slider-pointer:after {
  display: none;
}

.custom-slider-primary.ngx-slider .ngx-slider-bubble {
  bottom: 14px;
}

.custom-slider-primary .ngx-slider .ngx-slider-limit {
  font-weight: bold;
  color: $colorPrimary;
}

.custom-slider-primary .ngx-slider .ngx-slider-tick {
  width: 1px;
  height: 10px;
  margin-left: 4px;
  border-radius: 0;
  background: rgba(0, 0, 0, 0.36);
  top: -1px;
}

.custom-slider-primary.ngx-slider .ngx-slider-tick.ngx-slider-selected {
  background: $colorPrimary;
}

// We need to use ::ng-deep to overcome view encapsulation
.custom-slider-accent .ngx-slider .ngx-slider-bar {
  background: #ffe4d1;
  height: 2px;
}

.custom-slider-accent .ngx-slider .ngx-slider-selection {
  background: $colorAccent;
}

.custom-slider-accent .ngx-slider .ngx-slider-pointer {
  width: 10px;
  height: 10px;
  top: auto; /* to remove the default positioning */
  bottom: -2px;
  background-color: #333;
  border-radius: 50%;
}

.custom-slider-accent .ngx-slider .ngx-slider-pointer:after {
  display: none;
}

.custom-slider-accent.ngx-slider .ngx-slider-bubble {
  bottom: 14px;
}

.custom-slider-accent .ngx-slider .ngx-slider-limit {
  font-weight: bold;
  color: $colorAccent;
}

.custom-slider-accent .ngx-slider .ngx-slider-tick {
  width: 1px;
  height: 10px;
  margin-left: 4px;
  border-radius: 0;
  background: rgba(0, 0, 0, 0.36);
  top: -1px;
}

.custom-slider-accent.ngx-slider .ngx-slider-tick.ngx-slider-selected {
  background: $colorAccent;
}

.huna-class-selected-mouse-over {
  .selectable-item:hover {
    border-radius: 5px;
    //background: #dadada;
    color: $colorPrimary;
  }

  .selectable-item-accent:hover {
    border-radius: 5px;
    //background: #dadada;
    color: $colorPrimary;
  }


  @media screen and (max-width: 550px) {
    .selectable-item:hover {
      border-radius: unset;
      //background: #dadada;
      color: unset;
    }

    .selectable-item-accent:hover {
      border-radius: unset;
      //background: #dadada;
      color: unset;
    }
  }

  .selected {
    color: $colorPrimary;
  }

  .selected-accent {
    color: $colorAccent;
  }

}

.huna-table {
  .mat-sort-header-container {
    justify-content: center !important;
  }
}

.table-center-title {
  .mat-sort-header-container {
    justify-content: center !important;
  }

  .mat-sort-header-arrow {
    margin: 0;
  }
}

.icon-small {
  width: 16px !important;
  height: 16px !important;;
  font-size: 16px;
}

.icon-M {
  width: 24px !important;
  height: 24px !important;;
  font-size: 24px;
}

.icon-L {
  width: 36px !important;
  height: 36px !important;;
  font-size: 36px;
}


.text-title {
  font-weight: bold;
  font-size: medium;
}


.border-left-1-dotted-primary {
  border-left: 1px dotted $colorPrimary;
}

@mixin background-default-select {
  background: $colorPrimary;
  color: white;
}

.background-default-select {
  background: $colorPrimary;
  color: white;
}

.color-select {
  color: $colorPrimary !important;
}

.color-not-select {
  color: $colorAccent;
}


@mixin scale-default-select {
  transform: scale(1.02);
}

.scale-default-select {
  transform: scale(1.02);
}

.background-default-not-select {
  background: whitesmoke;
  color: #32373d;
}

.background-white {
  background-color: white;
  color: #32373d;
}

.opacity-disabled {
  opacity: 0.5;
}

.background-default-disabled {
  background: $disableColor;
  color: white;
}

.border-default-disabled {
  border-style: solid;
  border-color: $disableColor;
}

.huna-select {
  .background-default {
    @extend .background-default-select;
  }

  .color-select-white {
    color: white;
  }

  .background-default-color {
    @extend .background-default-select;
  }

  .background-white {
    background: white;
    color: black;
  }

  .color-default-color-text {
    @extend .color-select;
  }

  .default-color-text {
    @extend .color-select;
  }

  .default-color-text {
    @extend .color-select;
  }

  .img-icon-color-default {
    svg {
      fill: $colorPrimary;
    }
  }

  .icon-color-default {
    color: $colorPrimary;
  }

  .border {
    border: unset;
  }

  .scale {
    transform: scale(1);
    transition: transform 0.3s ease-out;
  }

  .cursor {
    cursor: pointer;
  }
}


.huna-select:hover {
  .background-default {
  }

  .scale {
    @extend .scale-default-select;
  }

}

.zoom-on-hover {
  transition: transform 150ms ease-in-out;

  &:hover {
    transform: scale(1.01);
  }
}

.huna-not-select {
  .background-default-color {
    @extend .background-default-not-select;
  }

  .img-icon-color-default {
    svg {
      fill: $colorAccent;
    }
  }

  .icon-color-default {
    color: $colorAccent;
  }

  .cursor {
    cursor: pointer;
  }
}

.huna-disabled {
  .background-default {
    @extend .background-default-disabled;
  }

  .background-default-color {
    @extend .background-default-disabled;
  }

  .background-white {
  }

  .border {
  }

  .cursor {
    cursor: default !important;
  }

  &:hover {
    cursor: default !important;
  }
}

.table {
  .selected-row {
    background: $colorPrimaryTransparent
  }

  .warning-row {
    background: $colorAccentTransparent
  }
}

.truncate-text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.truncate-text-2-line {
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box !important;
}

.truncate-text-3-line {
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box !important;
}

.example-custom-date-class {
  border-radius: 100%;

  .mat-calendar-body-cell-content {
    background-image: radial-gradient(mat.get-color-from-palette($candy-app-primary, default, 1), mat.get-color-from-palette($candy-app-primary, default, 0.6));
    color: white;
  }
}

.example-custom-date-class-preview {
  .mat-calendar-body-cell-content {
    background-image: radial-gradient(mat.get-color-from-palette($candy-app-primary, default, 0.6), mat.get-color-from-palette($candy-app-primary, default, 0.1));
    color: white;
  }
}

.select-text {
  .color-text {
    background: $colorPrimary;
    color: white;
    border-radius: 5px;
    font-weight: bold;
    transition: background 1000ms ease-in-out;
  }
}

.unset-color-text {
  .color-text {
    color: unset;
    font-weight: unset;
    background: unset !important;
    transition: 1000ms ease-in-out;
  }
}

.huna-button {
  span, .mat-button-wrapper { // I have tried all of the following options, but it does not work.
    max-width: 100% !important;
    white-space: pre-wrap;
  }
}

.mat-unset-color {
  background: #42424229;
  color: black;
}

.list-item-active, .list-item-active:hover {
  color: white !important;
  background-color: $colorPrimary !important;

}

.list-item-active:hover {
  opacity: 0.9;
}

.fill-color-text {
  svg {
    fill: rgba(0, 0, 0, 0.87);
  }

}

.mat-button-toggle-checked {
  background-color: $colorPrimary;
  color: white;
}

.mat-button-toggle:hover {
  background-color: $colorPrimary;
  color: white;
}

.mat-chip.mat-standard-chip {
  background: mat.get-color-from-palette(mat.$grey-palette, 100);
  color: mat.get-contrast-color-from-palette(mat.$grey-palette, 100);
  border: 1px solid rgba(0, 0, 0, 0.1);
}

.huna-form-field {
  .mat-form-field-appearance-fill .mat-form-field-flex {
    background-color: unset;
  }
}

.mat-form-field-without-underline {
  .mat-form-field-underline {
    display: none;
  }
}

.hide-arrow-input {
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type=number] {
    -moz-appearance: textfield;
  }
}