
mat-form-field {
  width: 100%;
}


mat-radio-button {
  margin-right: 15px;
}

div.mat-form-field {
  width: 100%;
  margin: 11px 0;
}

.mat-error {
  font-size: 10px;
  font-weight: 300;
}


.marginForCenterFormInTable {
  margin-bottom: -29px !important;
}
