button, .mat-button, .mat-flat-button, .mat-icon-button, .mat-stroked-button {
  text-transform: uppercase;
  font-weight: bold !important;
}

.mat-flat-button.mat-warn, .mat-raised-button.mat-warn, .mat-fab.mat-warn, .mat-mini-fab.mat-warn {
  color: white !important;
}

.mat-flat-button.mat-accent, .mat-raised-button.mat-accent, .mat-fab.mat-accent, .mat-mini-fab.mat-accent {
  color: white !important;
}

mat-button-toggle-group.accent {
  mat-button-toggle {
    color: rgba(0, 0, 0, .64) !important;
  }
  .mat-button-toggle-focus-overlay {
    background-color: #ff9800;
  }
  .mat-button-toggle-checked {
    background: #ff9800 !important;
    color: white !important;
  }
}

mat-button-toggle-group.accent-icon {
  background-color: transparent;

  mat-button-toggle {
    color: rgba(0, 0, 0, .64) !important;
    background-color: transparent!important;
    border: unset!important;
    border-radius: 10px;

  }
  .mat-button-toggle-focus-overlay {
    background-color: transparent!important;


  }
  .mat-button-toggle-checked {
    background: transparent !important;
    color: #ff6f00 !important;

  }
}